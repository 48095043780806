import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom';

export default function MetaPage() {
    const location = useLocation();
    const cateringPage = location.pathname.startsWith('/catering');
    const metaTitle = cateringPage ? 'Catering' : 'MightyMeals';
    const metaDescription = cateringPage ? `Catering with 
        MightyMeals is all about convenience. We'll take care of everything
        for you--from planning & coordinating delivery to setting &
        cleaning up--so that you get to eat, drink, & most importantly,
        enjoy quality time at your event!` :
        `Best rated fully cooked, ready-to-eat Meal 
        Prep Delivery Service in DC, NYC, Philadelphia, New Jersey, 
        Virginia, Maryland, Pennsylvania, North Carolina & Delaware!`;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{metaTitle} - Chef-Prepared Healthy Meals Delivered Fresh To You!</title>
            <meta name="description"
                content={metaDescription}
            />
            <meta
                property="og:description"
                content={metaDescription}
            />
        </Helmet>
    )
}
